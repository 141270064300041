// ======================================================================
// IMPORTS
// ======================================================================

import { SettingsAccessibility, TitleHeading } from 'js/components';

// ======================================================================
// COMPONENT
// ======================================================================

const Component = () => {
  return (
    <>
      <TitleHeading title="Accessibility Settings" />
      <SettingsAccessibility />
    </>
  );
};

// ======================================================================
// EXPORT
// ======================================================================

export default Component;
